import React from "react"
import Nav from "./nav.js"
import styles from "./header.module.css"

const Header = class Header extends React.Component {

  constructor(){
    super()
    this.state = {
      hasScrolled: false
    }
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount(){
    window.addEventListener('scroll', this.handleScroll)
  }

  handleScroll(){
    let scrollTop = window.pageYOffset

    if(scrollTop > 0){
      this.setState({
        hasScrolled: true
      })
    }else{
      this.setState({
        hasScrolled: false
      })
    }
  }

  render(){

    return(
      <header className={`${styles.header} ${this.state.hasScrolled ? styles.scrolled : ''}`}>
        <a className={styles.brand} href="/">&lt;Despain/&gt;</a>

          <Nav hasScrolled={this.state.hasScrolled} />
      </header>
    )
  }
}

export default Header;
