import React from "react"
import Img from "gatsby-image"
import { FaExternalLinkAlt } from 'react-icons/fa';
import { FaHtml5 } from 'react-icons/fa';
import { FaCss3Alt } from 'react-icons/fa';
import { FaJsSquare } from 'react-icons/fa';
import { FaPhp } from 'react-icons/fa';
import { FaJava } from 'react-icons/fa';
import { FaAndroid } from 'react-icons/fa';
import { FaVuejs } from 'react-icons/fa';
import { FaReact } from 'react-icons/fa';
import CustomIconGatsby from '../images/icons/gatsby.svg'
import CustomIconCockpit from '../images/icons/cockpit.svg'
import CustomIconJQuery from '../images/icons/jquery.svg'
import styles from "./project.module.css"

const Project = (props) => {

  let components = {
    'HTML5': <FaHtml5 />,
    'CSS3': <FaCss3Alt />,
    'JS': <FaJsSquare />,
    'JQuery': <CustomIconJQuery className={styles.custom}/>,
    'VueJS': <FaVuejs />,
    'PHP': <FaPhp />,
    'Java': <FaJava />,
    'AndroidSDK': <FaAndroid />,
    'React': <FaReact />,
    'GatsbyJS': <CustomIconGatsby className={styles.custom}/>,
    'Cockpit': <CustomIconCockpit className={styles.custom}/>,
  }

  // Fix for case where image is smaller than parent container
  // https://www.gatsbyjs.org/packages/gatsby-image/#avoiding-stretched-images-using-the-fluid-type
  let imgStyles = {
    maxWidth: props.projectDisplay.presentationWidth
  }

  return(
    <article className={styles.project}>
      <div className={styles.left}>
        <div className="overview">
          <h2 className={styles.title}>{props.projectName && props.projectName}</h2>
          <p className={styles.description}>{props.projectDescription && props.projectDescription}</p>
        </div>
        <div className={styles.tech}>
          {props.projectTech && props.projectTech.map((tech, i) => (
            <span key={i} className={styles.faIcon} title={tech}>
              {components[tech]}
            </span>
          ))}
        </div>
        <div className="buttons">
          {props.projectUrl && 
			<a className={styles.button} href={props.projectUrl}
				target="_blank"
				rel="noopener noreferrer">
				<FaExternalLinkAlt className={styles.icon}/>
				<span className={styles.text}>Visit</span>
			</a>}
        </div>
      </div>
      <div className={styles.right}>
		{props.projectDisplay &&
			<Img style={imgStyles} className={styles.preview} fluid={props.projectDisplay} />}
      </div>
    </article>
  )
}

export default Project
