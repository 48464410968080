import React from "react"
import styles from "./footer.module.css"

const Footer = class Footer extends React.Component{

  constructor(){
    super()
    this.state = {
      fields: {
        name: '',
        email: '',
        message: '',
        website: ''
      },
      response: ''
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name

    if(!target.value){
      target.classList.add(styles.error)
    }else{
      target.classList.remove(styles.error)
    }

    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        [name]: value
      }
    }))
  }

  handleSubmit = (event) => {
    event.preventDefault()

    let cockpitJsonObject = {
      form: this.state.fields
    }

    window.fetch('http://alexdespain.net/middleware.php', {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(cockpitJsonObject)
    })
      .then((response) => {
        response.text()
          .then((data) => this.setState({response: data}))
          .catch((error) => console.log(error))
      })
      .catch((error) => console.log(error))
  }

  render(){
    return(
      <footer id="contact" className={styles.footer}>
        <div className={styles.wrapper}>
          <h1 className={styles.heading}>Contact</h1>
          <form className={styles.contact} onSubmit={this.handleSubmit}>
            <div className={styles.row}>
              <div className={styles.field}>
                <input
                  type="text"
                  placeholder="Full Name"
                  name="name"
                  onChange={this.handleInputChange}
                  required
                />
              </div>
              <div className={styles.field}>
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  onChange={this.handleInputChange}
                  required
                />
              </div>
            </div>
            <div className={styles.field}>
              <textarea
                placeholder="Message"
                name="message"
                onChange={this.handleInputChange}
                required
              ></textarea>
            </div>
            <input
              className={styles.hidden}
              type="text" name="website"
              onChange={this.handleInputChange}
            />
            <button className={styles.button} type="submit">Submit</button>
            <div className={styles.response}>
              <p>{this.state.response}</p>
            </div>
          </form>
          <p>I'm currently not looking for freelance work but I am interested in employement opportunities.</p>
          <p>You can also connect to me on <a href="https://www.linkedin.com/in/alex-despain-9350215b" target="_blank" rel="noopener noreferrer">LinkedIn</a>!</p>
        </div>
      </footer>
    )
  }
}
export default Footer
