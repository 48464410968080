import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Project from "../components/Project"
import Intro from "../components/Intro"
import SEO from "../components/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title="Alex Despain - Dayton, OH Web Developer"
      keywords={[`Alex`, `Despain`, `Dayton`, `Web Developer`, `Web Designer`]}
    />
    <section id="home" className="intro">
      <Intro
        heading="Hey, I'm Alex!"
        text="I'm a full-stack web developer from Dayton, Ohio. I'm currently the Web Technologies Administrator at the City of Dayton."
      />
    </section>
    <section id="projects" className="projects">
      {data.allCockpitProjects.edges.map(({ node }) => (
        <Project
          key={node.cockpitId}
          projectName={node.name && node.name.value}
          projectDescription={node.description && node.description.value}
          projectTech={node.tech && node.tech.value}
          projectDisplay={node.Display && node.Display.value.childImageSharp.fluid}
          projectUrl={node.url && node.url.value}
        />
      ))}
    </section>
  </Layout>
)

export default IndexPage

export const query = graphql`
query {
      allCockpitProjects (sort:{fields:order___value, order:ASC}){
        edges {
          node {
            cockpitId
            name{
              value
            }
            description {
              value
            }
            tech{
              value
            }
            url{
              value
            }
            Display{
              value{
                publicURL
                childImageSharp{
                  fluid{
                    ...GatsbyImageSharpFluid
                    presentationWidth
                  }
                }
              }
            }
          }
        }
      }
    }
`
