import React from "react"
import { FaDownload } from 'react-icons/fa';
import { FaCube } from 'react-icons/fa';
import { FaComment } from 'react-icons/fa';
import Scrollchor from 'react-scrollchor';
import styles from "./intro.module.css"

const Intro = (props) => (
  <div className={styles.wrapper}>
    <h1 className={styles.heading}>{props.heading}</h1>
    <p className={styles.text}>{props.text}</p>
    <div className={styles.hline}></div>
    <div className={styles.buttons}>
      <a className={styles.button} href="/static/docs/Resume.docx">
        <FaDownload className={styles.icon}/>
        <span className={styles.text}>Resume</span>
      </a>
      <Scrollchor to="#projects" className={styles.button}>
        <FaCube className={styles.icon}/>
        <span className={styles.text}>Projects</span>
      </Scrollchor>
      <Scrollchor to="#contact" className={styles.button}>
        <FaComment className={styles.icon}/>
        <span className={styles.text}>Contact</span>
      </Scrollchor>
    </div>
  </div>
)

export default Intro
