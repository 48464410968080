import React from "react"
import { FaBars } from 'react-icons/fa';
import Scrollchor from 'react-scrollchor';
import styles from "./nav.module.css"

const Nav = class Nav extends React.Component {

  constructor(){
    super()
    this.state = {
      hasClickedHamburger: false
    }
    this.handleHamburgerClick = this.handleHamburgerClick.bind(this)
  }

  handleHamburgerClick(){
    console.log(this.state.hasClickedHamburger)
    this.setState({
      hasClickedHamburger: !this.state.hasClickedHamburger
    })
  }

  render(){
    return(
      <div className={`${styles.nav} ${this.props.hasScrolled ? styles.scrolled : ''}`}>
        <div className={styles.hamburger} onClick={this.handleHamburgerClick}>
          <FaBars className={styles.icon} />
        </div>
        <nav className={`${styles.menu} ${this.state.hasClickedHamburger ? styles.showHamburgerMenu : ''}`}>
          <Scrollchor to="#home" className={styles.item}>Home</Scrollchor>
          <Scrollchor to="#projects" className={styles.item}>Projects</Scrollchor>
          <Scrollchor to="#contact" className={styles.item}>Contact</Scrollchor>
        </nav>
      </div>
    )
  }
}

export default Nav
